import { defineStore } from 'pinia'

import router from '@/router/index'
import axios from '@/api/index'
import qs from 'qs'
import Nprogress from 'nprogress';
import  '../../node_modules/js-loading-overlay/dist/js-loading-overlay.min.js'
export const usedashboardStore = defineStore('dashboardstore', {

    state: () => {
        return {
            overviewList: [],
            getOverviewList: [],
            createSubzoneDetail: [],
            userCountDetailsList:[],
            activeSessionDetailsList:[],
            activeOnlineUserDetailsList:[],
            dataTransferDetailsList:[],
            userDataTransferDetailsList:[],
            activeUserDetailsList:[],
            activeUserAccountDetailsList:[],
            totalUserDetailsList:[],
            paymentDetailsList:[],
            ticketDetailsList:[],
            leadDetailsList:[],
            revenueDetailsList:[],
            franchiseRevenueDetailsList:[],
            paymentCollectionDayWiseDetailsList:[],
            paymentCollectionAccountWiseDetailsList:[],
            nasWiseActiveSessionDetailsList:[],
            newRegistrationDetailsList:[],
            renewedUsersDetailsList:[],
            totalExpiredUsersDetailsList:[],
            loginTypeDetailsList:[],
            payAsYouGoUserDetailsList:[],
            tasksCountDetailsList:[],
            helpDeskCountDetailsList:[],
            renewalDetailsList:[],
            sessionCountList:[],
            dashboardDetailsList:[]


        }
    },
    getters: {
        overviewListState: (state) => state.overviewList,
        overviewDetailsListGetters() {
            return this.overviewListState;
        },
        getOverviewListState: (state) => state.getOverviewList,
        getOverviewDetailsListGetters() {
            return this.getOverviewListState;
        },
        alluserCountDetailsList: (state) => state.userCountDetailsList,
        userCountDetailsListGetters() {
            return this.alluserCountDetailsList;
        },
        allactiveSessionDetailsList: (state) => state.activeSessionDetailsList,
        activeSessionDetailsListGetters() {
            return this.allactiveSessionDetailsList;
        },
        allactiveOnlineUserDetailsList: (state) => state.activeOnlineUserDetailsList,
        activeOnlineUserDetailsListGetters() {
            return this.allactiveOnlineUserDetailsList;
        },
        alldataTransferDetailsList: (state) => state.dataTransferDetailsList,
        dataTransferDetailsListGetters() {
            return this.alldataTransferDetailsList;
        },
        userDataTransferDetails: (state) => state.userDataTransferDetailsList,
        userDataTransferDetailsGetters() {
            return this.userDataTransferDetails;
        },
        allactiveUserDetailsList: (state) => state.activeUserDetailsList,
        activeUserDetailsListGetters() {
            return this.allactiveUserDetailsList;
        },
        allactiveUserAccountDetailsList: (state) => state.activeUserAccountDetailsList,
        activeUserAccountDetailsListGetters() {
            return this.allactiveUserAccountDetailsList;
        },
        alltotalUserDetailsList: (state) => state.totalUserDetailsList,
        totalUserDetailsListGetters() {
            return this.alltotalUserDetailsList;
        },
        allpaymentDetailsList: (state) => state.paymentDetailsList,
        paymentDetailsListGetters() {
            return this.allpaymentDetailsList;
        },
        allticketDetailsList: (state) => state.ticketDetailsList,
        ticketDetailsListGetters() {
            return this.allticketDetailsList;
        },
        allleadDetailsList: (state) => state.leadDetailsList,
        leadDetailsListGetters() {
            return this.allleadDetailsList;
        },
        allrevenueDetailsList: (state) => state.revenueDetailsList,
        revenueDetailsListGetters() {
            return this.allrevenueDetailsList;
        },
        allfranchiseRevenueDetailsList: (state) => state.franchiseRevenueDetailsList,
        franchiseRevenueDetailsListGetters() {
            return this.allfranchiseRevenueDetailsList;
        },
        allpaymentCollectionDayWise: (state) => state.paymentCollectionDayWiseDetailsList,
        paymentCollectionDayWiseGetters() {
            return this.allpaymentCollectionDayWise;
        },
        allpaymentCollectionAccountWise: (state) => state.paymentCollectionAccountWiseDetailsList,
        paymentCollectionAccountWiseGetters() {
            return this.allpaymentCollectionAccountWise;
        },
        allnasWiseActiveSession: (state) => state.nasWiseActiveSessionDetailsList,
        nasWiseActiveSessionGetters() {
            return this.allnasWiseActiveSession;
        },
        allnewRegistration: (state) => state.newRegistrationDetailsList,
        newRegistrationGetters() {
            return this.allnewRegistration;
        },
        allrenewedUsers: (state) => state.renewedUsersDetailsList,
        renewedUsersGetters() {
            return this.allrenewedUsers;
        },
        allTotalExpiredUsers: (state) => state.totalExpiredUsersDetailsList,
        TotalExpiredUsersGetters() {
            return this.allTotalExpiredUsers;
        },
        allloginType: (state) => state.loginTypeDetailsList,
        loginTypeGetters() {
            return this.allloginType;
        },
        allpayAsYouGoUserCount: (state) => state.payAsYouGoUserDetailsList,
        payAsYouGoUserCountGetters() {
            return this.allpayAsYouGoUserCount;
        },
        alltasksCount: (state) => state.tasksCountDetailsList,
        tasksCountGetters() {
            return this.alltasksCount;
        },
        allhelpDeskCount: (state) => state.helpDeskCountDetailsList,
        helpDeskCountGetters() {
            return this.allhelpDeskCount;
        },
        allrenewalDetails: (state) => state.renewalDetailsList,
        renewalDetailsGetters() {
            return this.allrenewalDetails;
        },
        allsessionCountList: (state) => state.sessionCountList,
        sessionCountListGetters() {
            return this.allsessionCountList;
        },
        alldashboardDetailsList: (state) => state.dashboardDetailsList,
        dashboardDetailsListGetters() {
            return this.alldashboardDetailsList;
        },
        createSubzoneDetailState: (state) => state.createSubzoneDetail,
        createSubzoneDetailGetters() {
            return this.createSubzoneDetailState;
        },
    },
    actions: {
        fetchOverviewDetails(postData) {
            Nprogress.start()
            axios.get('/dashboard/set_users_count/' + postData.account + '/overviewDetails')
                .then(res => {
                    Nprogress.done()
                    this.overviewList = new Array();
                    var tData = {};
                    var parseData = res.data;
                    if (Object.keys(this.overviewList).length == 0) {
                        tData['OverviewDetailsList'] = parseData;
                        this.overviewList = tData;
                    } else {
                        Vue.set(this.overviewList, parseData);
                    };                    
    
                })
                .catch(error => console.log(error))
        },
        fetchGetOverviewDetails(postData) {
            Nprogress.start()
            axios.get('/dashboard/get_overview_details/' + postData.duration + '/' + postData.account)
                .then(res => {
                    Nprogress.done()
                    this.getOverviewList = new Array();
                    var tData = {};
                    var parseData = res.data;
                    if (Object.keys(this.getOverviewList).length == 0) {
                        tData['GetOverviewDetailsList'] = parseData;
                        this.getOverviewList = tData;
                    } else {
                        Vue.set(this.getOverviewList, parseData);
                    };                    
    
                })
                .catch(error => console.log(error))
        },

        fetchUserCountDetails(postData) {
            Nprogress.start()
            axios.get('/dashboard/set_users_count/' + postData.account + '/' + postData.detailType)
                .then(res => {
                    Nprogress.done()
                    this.userCountDetailsList = new Array();
                    //commit('SET_USER_COUNT_LIST', res.data)
                    var tData = {};
                    var parseData = res.data.res;
                    if (Object.keys(this.userCountDetailsList).length == 0) {
                        tData['UserCountDetailsList'] = parseData;
                        this.userCountDetailsList = tData;
                    } else {
                        Vue.set(this.userCountDetailsList, parseData);
                    };
    
                })
                .catch(error => console.log(error))
        },
        fetchActiveSessionDetails(postData) {
            Nprogress.start()
            axios.get('/dashboard/active_sessions_count/' + postData.duration + '/' + postData.account)
                .then(res => {
                    Nprogress.done()
                    this.activeSessionDetailsList = new Array();
                    //commit('SET_ACTIVE_SESSION_LIST', res.data)
                    var tData = {};
                    var parseData = res.data;
                    if (Object.keys(this.activeSessionDetailsList).length == 0) {
                        tData['ActiveSessionDetailsList'] = parseData;
                        this.activeSessionDetailsList = tData;
                    } else {
                        Vue.set(this.activeSessionDetailsList, parseData);
                    };
    
                })
                .catch(error => console.log(error))
        },
        fetchActiveOnlineUserDetails(postData) {
            Nprogress.start()
            axios.get('/dashboard/users_count_graph/' + postData.duration + '/' + postData.account)
                .then(res => {
                    Nprogress.done()
                    this.activeOnlineUserDetailsList = new Array();
                    //commit('SET_ACTIVE_ONLINE_USER_LIST', res.data)
                    var tData = {};
                    var parseData = res.data;
                    if (Object.keys(this.activeOnlineUserDetailsList).length == 0) {
                        tData['ActiveOnlineUserDetailsList'] = parseData;
                        this.activeOnlineUserDetailsList = tData;
                    } else {
                        Vue.set(this.activeOnlineUserDetailsList, parseData);
                    };
    
                })
                .catch(error => console.log(error))
        },
        fetchDataTransferDetails(postData) {
            Nprogress.start()
            axios.get('/dashboard/traffic_data/' + postData.duration + '/' + postData.account)
                .then(res => {
                    Nprogress.done()
                    this.dataTransferDetailsList = new Array();
                    //commit('SET_DATA_TRANSFER_LIST', res.data)
                    var tData = {};
                    var parseData = res.data;
                    if (Object.keys(this.dataTransferDetailsList).length == 0) {
                        tData['DataTransferDetailsList'] = parseData;
                        this.dataTransferDetailsList = tData;
                    } else {
                        Vue.set(this.dataTransferDetailsList, parseData);
                    };
    
                })
                .catch(error => console.log(error))
        },
        fetchUserDataTransferDetails(postData) {
            Nprogress.start()
            axios.get('/users/user_traffic_data/' + postData.userId + '/' + postData.duration + '/' + postData.account)
                .then(res => {
                    Nprogress.done()
                    this.userDataTransferDetailsList = new Array();
                    var tData = {};
                    var parseData = res.data;
                    if (Object.keys(this.userDataTransferDetailsList).length == 0) {
                        tData['UserDataTransferDetailsList'] = parseData;
                        this.userDataTransferDetailsList = tData;
                    } else {
                        Vue.set(this.userDataTransferDetailsList, parseData);
                    };
                })
                .catch(error => console.log(error))
        },
        fetchActiveUserDetails(postData) {
            Nprogress.start()
            axios.get('/dashboard/active_users_count_all/' + postData.duration + '/' + postData.account)
                .then(res => {
                    Nprogress.done()
                    this.activeUserDetailsList = new Array();
                    //commit('SET_ACTIVE_USER_LIST', res.data)
                    var tData = {};
                    var parseData = res.data;
                    if (Object.keys(this.activeUserDetailsList).length == 0) {
                        tData['ActiveUserDetailsList'] = parseData;
                        this.activeUserDetailsList = tData;
                    } else {
                        Vue.set(this.activeUserDetailsList, parseData);
                    };
    
                })
                .catch(error => console.log(error))
        },
        fetchActiveUserAccountDetails(postData) {
            Nprogress.start()
            axios.get('/dashboard/active_users_account_wise_graph/' + postData.duration + '/' + postData.account)
                .then(res => {
                    Nprogress.done()
                    this.activeUserAccountDetailsList = new Array();
                    //commit('SET_ACTIVE_USER_ACCOUNT_LIST', res.data)
                    var tData = {};
                    var parseData = res.data;
                    if (Object.keys(this.activeUserAccountDetailsList).length == 0) {
                        tData['ActiveUserAccountDetailsList'] = parseData;
                        this.activeUserAccountDetailsList = tData;
                    } else {
                        Vue.set(this.activeUserAccountDetailsList, parseData);
                    };
    
                })
                .catch(error => console.log(error))
        },
        fetchTotalUserDetails(postData) {
            Nprogress.start()
            axios.get('/dashboard/total_users_graph/' + postData.duration + '/' + postData.account)
                .then(res => {
                    Nprogress.done()
                    this.totalUserDetailsList = new Array();
                    //commit('SET_TOTAL_USER_LIST', res.data)
                    var tData = {};
                    var parseData = res.data;
                    if (Object.keys(this.totalUserDetailsList).length == 0) {
                        tData['TotalUserDetailsList'] = parseData;
                        this.totalUserDetailsList = tData;
                    } else {
                        Vue.set(this.totalUserDetailsList, parseData);
                    };
    
                })
                .catch(error => console.log(error))
        },
        fetchPaymentDetails(postData) {
            Nprogress.start()
            axios.get('/dashboard/payment_type/' + postData.duration + '/' + postData.account)
                .then(res => {
                    Nprogress.done()
                    this.paymentDetailsList = new Array();
                    //commit('SET_PAYMENT_LIST', res.data)
                    var tData = {};
                    var parseData = res.data;
                    if (Object.keys(this.paymentDetailsList).length == 0) {
                        tData['PaymentDetailsList'] = parseData;
                        this.paymentDetailsList = tData;
                    } else {
                        Vue.set(this.paymentDetailsList, parseData);
                    };
    
                })
                .catch(error => console.log(error))
        },
        fetchTicketDetails(postData) {
            Nprogress.start()
            axios.get('/dashboard/tickets_status/' + postData.duration + '/' + postData.account)
                .then(res => {
                    Nprogress.done()
                    this.ticketDetailsList = new Array();
                    //commit('SET_TICKET_LIST', res.data)
                    var tData = {};
                    var parseData = res.data;
                    if (Object.keys(this.ticketDetailsList).length == 0) {
                        tData['TicketDetailsList'] = parseData;
                        this.ticketDetailsList = tData;
                    } else {
                        Vue.set(this.ticketDetailsList, parseData);
                    };
    
                })
                .catch(error => console.log(error))
        },
        fetchLeadDetails(postData) {
            Nprogress.start()
            axios.get('/dashboard/lead_users_status_graph/' + postData.duration + '/' + postData.account)
                .then(res => {
                    Nprogress.done()
                    this.leadDetailsList = new Array();
                    //commit('SET_LEAD_LIST', res.data)
                    var tData = {};
                    var parseData = res.data;
                    if (Object.keys(this.leadDetailsList).length == 0) {
                        tData['LeadDetailsList'] = parseData;
                        this.leadDetailsList = tData;
                    } else {
                        Vue.set(this.leadDetailsList, parseData);
                    };
    
                })
                .catch(error => console.log(error))
        },
        fetchRevenueDetails(postData) {
            Nprogress.start()
            axios.get('/dashboard/revenue_status_graph/' + postData.duration + '/' + postData.account)
                .then(res => {
                    Nprogress.done()
                    this.revenueDetailsList = new Array();
                    //commit('SET_REVENUE_LIST', res.data)
                    var tData = {};
                    var parseData = res.data;
                    if (Object.keys(this.revenueDetailsList).length == 0) {
                        tData['RevenueDetailsList'] = parseData;
                        this.revenueDetailsList = tData;
                    } else {
                        Vue.set(this.revenueDetailsList, parseData);
                    };
    
                })
                .catch(error => console.log(error))
        },
        fetchfranchiseRevenueDetails(postData) {
            Nprogress.start()
            axios.get('/FranchiseManagement/get_revenue_details/' + postData.account)
                .then(res => {
                    Nprogress.done()
                    this.franchiseRevenueDetailsList = new Array();
                    //commit('SET_FRANCHISE_REVENUE_LIST', res.data)
                    var tData = {};
                    var parseData = res.data;
                    if (Object.keys(this.franchiseRevenueDetailsList).length == 0) {
                        tData['FranchiseRevenueDetailsList'] = parseData;
                        this.franchiseRevenueDetailsList = tData;
                    } else {
                        Vue.set(this.franchiseRevenueDetailsList, parseData);
                    };
    
                })
                .catch(error => console.log(error))
        },
        fetchPaymentCollectionDayWiseDetails(postData) {
            Nprogress.start()
            axios.get('dashboard/day_wise_payment_amount_graph/' + postData.duration + '/' + postData.account)
                .then(res => {
                    Nprogress.done()
                    this.paymentCollectionDayWiseDetailsList = new Array();
                    //commit('SET_PAYMENT_COLLECTION_DAYWISE_LIST', res.data)
                    var tData = {};
                    var parseData = res.data;
                    if (Object.keys(this.paymentCollectionDayWiseDetailsList).length == 0) {
                        tData['PaymentCollectionDayWiseDetailsList'] = parseData;
                        this.paymentCollectionDayWiseDetailsList = tData;
                    } else {
                        Vue.set(this.paymentCollectionDayWiseDetailsList, parseData);
                    };
    
                })
                .catch(error => console.log(error))
        },
        fetchPaymentCollectionAccountWiseDetails(postData) {
            Nprogress.start()
            axios.get('dashboard/account_wise_payment_amount_graph/' + postData.duration + '/' + postData.account)
                .then(res => {
                    Nprogress.done()
                    this.paymentCollectionAccountWiseDetailsList = new Array();
                    //commit('SET_PAYMENT_COLLECTION_ACCOUNTWISE_LIST', res.data)
                    var tData = {};
                    var parseData = res.data;
                    if (Object.keys(this.paymentCollectionAccountWiseDetailsList).length == 0) {
                        tData['PaymentCollectionAccountWiseDetailsList'] = parseData;
                        this.paymentCollectionAccountWiseDetailsList = tData;
                    } else {
                        Vue.set(this.paymentCollectionAccountWiseDetailsList, parseData);
                    };
    
                })
                .catch(error => console.log(error))
        },
        fetchNasWiseActiveSessionDetails(postData) {
            Nprogress.start()
            axios.get('dashboard/nas_wise_active_sessions_graph/' + postData.duration + '/' + postData.account)
                .then(res => {
                    Nprogress.done()
                    this.nasWiseActiveSessionDetailsList = new Array();
                    //commit('SET_NAS_WISE_ACTIVE_SESSION_LIST', res.data)
                    var tData = {};
                    var parseData = res.data;
                    if (Object.keys(this.nasWiseActiveSessionDetailsList).length == 0) {
                        tData['NasWiseActiveSessionDetailsList'] = parseData;
                        this.nasWiseActiveSessionDetailsList = tData;
                    } else {
                        Vue.set(this.nasWiseActiveSessionDetailsList, parseData);
                    };
    
                })
                .catch(error => console.log(error))
        },
        fetchNewRegistrationDetails(postData) {
            Nprogress.start()
            axios.get('dashboard/new_registration/' + postData.duration + '/' + postData.account)
                .then(res => {
                    Nprogress.done()
                    this.newRegistrationDetailsList = new Array();
                    //commit('SET_NEW_REGISTRATION_LIST', res.data)
                    var tData = {};
                    var parseData = res.data;
                    if (Object.keys(this.newRegistrationDetailsList).length == 0) {
                        tData['NewRegistrationDetailsList'] = parseData;
                        this.newRegistrationDetailsList = tData;
                    } else {
                        Vue.set(this.newRegistrationDetailsList, parseData);
                    };
    
                })
                .catch(error => console.log(error))
        },
        fetchRenewedUsersDetails(postData) {
            Nprogress.start()
            axios.get('dashboard/renewed_users/' + postData.duration + '/' + postData.account)
                .then(res => {
                    Nprogress.done()
                    this.renewedUsersDetailsList = new Array();
                    //commit('SET_RENEWED_USER_LIST', res.data)
                    var tData = {};
                    var parseData = res.data;
                    if (Object.keys(this.renewedUsersDetailsList).length == 0) {
                        tData['RenewedUsersDetailsList'] = parseData;
                        this.renewedUsersDetailsList = tData;
                    } else {
                        Vue.set(this.renewedUsersDetailsList, parseData);
                    };
    
                })
                .catch(error => console.log(error))
        },
        fetchTotalExpiredUsersDetails(postData) {
            Nprogress.start()
            axios.get('dashboard/total_expired_users_graph/' + postData.duration + '/' + postData.account)
                .then(res => {
                    Nprogress.done()
                    this.totalExpiredUsersDetailsList = new Array();
                    //commit('SET_TOTAL_EXPIRED_USER_LIST', res.data)
                    var tData = {};
                    var parseData = res.data;
                    if (Object.keys(this.totalExpiredUsersDetailsList).length == 0) {
                        tData['TotalExpiredUsersDetailsList'] = parseData;
                        this.totalExpiredUsersDetailsList = tData;
                    } else {
                        Vue.set(this.totalExpiredUsersDetailsList, parseData);
                    };
    
                })
                .catch(error => console.log(error))
        },
        fetchLoginTypeDetails(postData) {
            Nprogress.start()
            axios.get('analytics/login_type/' + postData.duration + '/dash' + '/' + postData.account)
                .then(res => {
                    Nprogress.done()
                    this.loginTypeDetailsList = new Array();
                    //commit('SET_LOGIN_TYPE_LIST', res.data)
                    var tData = {};
                    var parseData = res.data;
                    if (Object.keys(this.loginTypeDetailsList).length == 0) {
                        tData['LoginTypeDetailsList'] = parseData;
                        this.loginTypeDetailsList = tData;
                    } else {
                        Vue.set(this.loginTypeDetailsList, parseData);
                    };
    
                })
                .catch(error => console.log(error))
        },
        fetchPayAsYouGoUserDetails(postData) {
            Nprogress.start()
            axios.get('/dashboard/set_users_count' + '/' + postData.account + '/payAsYouGoUsersCount')
                .then(res => {
                    Nprogress.done()
                    this.payAsYouGoUserDetailsList = new Array();
                    //commit('SET_PAYAS_USER_COUNT_LIST', res.data)
                    var tData = {};
                    var parseData = res.data;
                    if (Object.keys(this.payAsYouGoUserDetailsList).length == 0) {
                        tData['PayAsYouGoUserDetailsList'] = parseData;
                        this.payAsYouGoUserDetailsList = tData;
                    } else {
                        Vue.set(this.payAsYouGoUserDetailsList, parseData);
                    };
    
                })
                .catch(error => console.log(error))
        },
        fetchtaskCountDetails(postData) {
            Nprogress.start()
            axios.get('/dashboard/set_users_count' + '/' + postData.account + '/tasksCount')
                .then(res => {
                    Nprogress.done()
                    this.tasksCountDetailsList = new Array();
                    //commit('SET_TASK_COUNT_LIST', res.data)
                    var tData = {};
                    var parseData = res.data;
                    if (Object.keys(this.tasksCountDetailsList).length == 0) {
                        tData['TaskCountDetailsList'] = parseData;
                        this.tasksCountDetailsList = tData;
                    } else {
                        Vue.set(this.tasksCountDetailsList, parseData);
                    };
    
                })
                .catch(error => console.log(error))
        },
        fetchHelpDeskCountDetails(postData) {
            Nprogress.start()
            axios.get('/dashboard/set_users_count' + '/' + postData.account + '/helpDeskCount')
                .then(res => {
                    Nprogress.done()
                    this.helpDeskCountDetailsList = new Array();
                    //commit('SET_HELPDESK_COUNT_LIST', res.data)
                    var tData = {};
                    var parseData = res.data;
                    if (Object.keys(this.helpDeskCountDetailsList).length == 0) {
                        tData['HelpDeskCountDetailsList'] = parseData;
                        this.helpDeskCountDetailsList = tData;
                    } else {
                        Vue.set(this.helpDeskCountDetailsList, parseData);
                    };
                
                })
                .catch(error => console.log(error))
        },
        fetchRenewalDetailsList(postData) {
            Nprogress.start()
            axios.get('/dashboard/set_users_count' + '/' + postData.account + '/renewalDetails')
                .then(res => {
                    Nprogress.done()
                    this.renewalDetailsList = new Array();
                    //commit('SET_RENEWAL_LIST', res.data)
                    var tData = {};
                    var parseData = res.data;
                    if (Object.keys(this.renewalDetailsList).length == 0) {
                        tData['RenewalDetailsList'] = parseData;
                        this.renewalDetailsList = tData;
                    } else {
                        Vue.set(this.renewalDetailsList, parseData);
                    };
    
                })
                .catch(error => console.log(error))
        },
        fetchSessionCountDetails(postData) {
            Nprogress.start()
            axios.get('/analytics/dashboard_analytics_data/' + postData.duration + '/dash/' + postData.account)
                .then(res => {
                    Nprogress.done()
                    this.sessionCountList = new Array();
                    //commit('SET_SESSION_COUNT_LIST', res.data)
                    var tData = {};
                    var parseData = res.data;
                    if (Object.keys(this.sessionCountList).length == 0) {
                        tData['SessionCountDetailsList'] = parseData;
                        this.sessionCountList = tData;
                    } else {
                        Vue.set(this.sessionCountList, parseData);
                    };
    
                })
                .catch(error => console.log(error))
        },
        clearDashboardsState() {
            //commit('CLEAR_DASHBOARD_STATE')
            this.dashboardDetailsList = [];
            this.userCountDetailsList = [];
            this.activeSessionDetailsList = [];
            this.dataTransferDetailsList = [];
            this.userDataTransferDetailsList = [];
            this.activeUserDetailsList = [];
            this.activeOnlineUserDetailsList =[];
            this.activeUserAccountDetailsList = [];
            this.totalUserDetailsList = [];
            this.paymentDetailsList = [];
            this.ticketDetailsList = [];
            this.leadDetailsList = [];
            this.revenueDetailsList = [];
            this.franchiseRevenueDetailsList = [];
            this.paymentCollectionDayWiseDetailsList = [];
            this.paymentCollectionAccountWiseDetailsList = [];
            this.nasWiseActiveSessionDetailsList = [];
            this.newRegistrationDetailsList = [];
            this.renewedUsersDetailsList = [];
            this.totalExpiredUsersDetailsList = [];
            this.loginTypeDetailsList = [];
            this.payAsYouGoUserDetailsList = [];
            this.tasksCountDetailsList = [];
            this.helpDeskCountDetailsList = [];
            this.renewalDetailsList = [];
            this.sessionCountList = [];
            this.overviewList = [];
            this.getOverviewList = [];
            this.createSubzoneDetail = [];            
        },
        fetchCreateSubzoneDetail(postData) {
            Nprogress.start()
            axios.get('/dashboard/create_subaccount/' + postData.accountId + '/' + true)
                .then(res => {
                    Nprogress.done()
                    this.createSubzoneDetail = res.data.message;
                })
                .catch(error => console.log(error))
        },
        gotoBackStepSubmit(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/dashboard/go_to_back_step', qs.stringify(postData, {
                    parseArrays: false
                })).then(response => {
                    JsLoadingOverlay.hide();
                    if (response.data.status != "success") {
                        reject(response.data.message);
                    } else {
                        resolve(response.data);
                    }    
                })
                .catch(error => console.log(error));
            });
        },
        createSubAccountSubmit(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/dashboard/create_sub_account_data', postData)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status == "pending") {
                            reject("pending");
                        } else if (response.data.status != "success") {
                            reject(response.data.message);
                        } else {
                            if (response.data.message != null && response.data.message != '') {
                                var url = "/dashboard/create_subaccount" + '/' + response.data.message;
                                router.push(url);
                                resolve(response.data);
                            } else {
                                var msg = "Sub-account <strong>" + postData.subAccountName + "</strong> has been created.";
                                resolve(msg);
                            }
    
                        }
    
                    })
                    .catch(error => console.log(error));
            });
        },
        createcopySettingsSubmit(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/dashboard/copy_account_settings', postData)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != "success") {
                            reject(response.data.message);
                        } else {
                            resolve(response.data);
                        }
    
                    })
                    .catch(error => console.log(error));
            });
        },
        prefixSettingsSubmit(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/dashboard/prefix_settings', postData)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != "success") {
                            reject(response.data.message);
                        } else {
                            resolve(response.data);
                        }    
                    })
                    .catch(error => console.log(error));
            });
        },
        routerSettingSubmit(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/access_points/add_router_subaccount', qs.stringify(postData, {
                    parseArrays: false
                })).then(response => {
                    JsLoadingOverlay.hide();
                    if (response.data.status != "success") {
                        reject(response.data.message);
                    } else {
                        resolve(response.data);
                    }    
                })
                .catch(error => console.log(error));
            });
        },
        tagPaymentGatewaySubmit(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/dashboard/tag_payment_franchise', qs.stringify(postData, {
                    parseArrays: false
                }))
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != "success") {
                            reject(response.data.message);
                        } else {
                            resolve(response.data);
                        }    
                    })
                    .catch(error => console.log(error));
            });
        },
        skipcreateAdminAccountSubmit(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/dashboard/skip_create_admin', qs.stringify(postData, {
                    parseArrays: false
                })).then(response => {
                    JsLoadingOverlay.hide();
                    if (response.data.status != "success") {
                        reject(response.data.message);
                    } else {
                        router.push("/dashboard");
                    }    
                })
                    .catch(error => console.log(error));
            });
        },
        addAdminCreateFormSubmit(postData) {
            return new Promise((resolve, reject) => {
                JsLoadingOverlay.show({
                    'spinnerIcon': 'ball-clip-rotate',
                    "overlayOpacity": "0.6",
                    "spinnerSize": "2x",
                    "lockScroll": true,
                });
                axios.post('/network_admins/add_admin', postData)
                    .then(response => {
                        JsLoadingOverlay.hide();
                        if (response.data.status != "success") {
                            reject(response.data.message);
                        } else {
                            router.push("/dashboard");
                        }
    
                    })
                    .catch(error => console.log(error));
            });
        },
        fetchDashboardDetail(postData) {
            Nprogress.start()
            return new Promise((resolve, reject) => {
                axios.get('/dashboard/index/' + true + '/' + postData.account + "/" + true)
                    .then(res => {
                        Nprogress.done()
                        if (res.data.status == "redirect") {
                            router.push(res.data.message);
                        } else {
                            if (postData.checkTrue != "checkTrue") {
                                this.dashboardDetailsList = new Array();
                                //commit('SET_DASHBOARD_DETAILS_LIST', res.data)
                                var tData = {};
                                var parseData = res.data.message;
                                if (Object.keys(this.dashboardDetailsList).length == 0) {
                                    tData['DashBoardDetailsList'] = parseData;
                                    this.dashboardDetailsList = tData;
                                } else {
                                    Vue.set(this.dashboardDetailsList, parseData);
                                };
                            } else {
                                resolve(res.data);
                            }
                        }
                    })
                    .catch(error => console.log(error))
            });
        },
        fetchOverviewDetails(postData) {
            Nprogress.start()
            axios.get('/dashboard/set_users_count/' + postData.account + '/overviewDetails')
                .then(res => {
                    Nprogress.done()
                    this.overviewList = new Array();
                    //commit('SET_OVERVIEW_LIST', res.data)
                    var tData = {};
                    var parseData = res.data;
                    if (Object.keys(this.overviewList).length == 0) {
                        tData['OverviewDetailsList'] = parseData;
                        this.overviewList = tData;
                    } else {
                        Vue.set(this.overviewList, parseData);
                    };
    
                })
                .catch(error => console.log(error))
        },
        fetchGetOverviewDetails(postData) {
            Nprogress.start()
            axios.get('/dashboard/get_overview_details/' + postData.duration + '/' + postData.account)
                .then(res => {
                    Nprogress.done()
                    this.getOverviewList = new Array();
                    //commit('SET_ALL_OVERVIEW_LIST', res.data)
                    var tData = {};
                    var parseData = res.data;
                    if (Object.keys(this.getOverviewList).length == 0) {
                        tData['GetOverviewDetailsList'] = parseData;
                        this.getOverviewList = tData;
                    } else {
                        Vue.set(this.getOverviewList, parseData);
                    };
    
                })
                .catch(error => console.log(error))
        },
       
    }
});