import { defineStore } from 'pinia'
import router from '@/router/index'
import axios from '@/api/index'
import Nprogress from 'nprogress';
import  '../../node_modules/js-loading-overlay/dist/js-loading-overlay.min.js'
import qs, { parse } from 'qs'
export const uselayoutStore = defineStore('layoutstore', {
    state: () => {
      return {
        headerDetails: [],
        subaccDetails:[],
        checkDbs:false,
        subAccountMergeExtraArr:[],
        franchiseBbpsAccount: [],
        generateSubAccountsTreeVue:[],
        adminDetails: [],
        bankCurrencyCountryDetails: [],
      }
    },
    getters: {
        headerDetailsData: (state) => state.headerDetails,
        headerDetailsGetter() {
            return this.headerDetailsData
        },
        generateSubAccountsTreeVueData: (state) => state.generateSubAccountsTreeVue,
        generateSubAccountsTreeVueGetter(){
            return this.generateSubAccountsTreeVueData
        },
        subaccDetailsData:(state) => state.subaccDetails,
        subaccDetailsGetter() {
            return this.subaccDetailsData
        },
        subaccDetailsTreeData:(state) => state.subaccDetailsTree,
        subaccDetailsTreeGetter() {
            return this.subaccDetailsTreeData
        },
        subAccountMergeExtraArrData:(state)=>state.subAccountMergeExtraArr,
        subAccountMergeExtraArrGetter(){
            return this.subAccountMergeExtraArr
        },
        franchiseBbpsAccountData:(state)=>state.franchiseBbpsAccount,
        franchiseBbpsAccountGetter(){
            return this.franchiseBbpsAccount
        },
        adminDetailsData:(state)=>state.adminDetails,
        adminDetailsGetter(){
            return this.adminDetails
        },
        bankCurrencyCountryListData:(state)=>state.bankCurrencyCountryDetails,
        bankCurrencyCountryListGetter(){
            return this.bankCurrencyCountryDetails
        },
        checkDbsData:(state) => state.checkDbs,
        checkDbsGetter() {
            return this.checkDbsData
        },
        socket_raiseTicketNotification() {
            return this.headerDetailsData
        },
        socket_unassignedTaskNotify() {
            return this.headerDetailsData
        },
        socket_unReadMessageNotify() {
            return this.headerDetailsData
        },
        socket_assignedTaskNotify() {
            return this.headerDetailsData
        },
        socket_userPendingNotify() {
            return this.headerDetailsData
        },
        socket_natlogFailures() {
            return this.headerDetailsData
        },
    },
    actions: {
        fetchHeaderDetails() {
            return new Promise((resolve) => {
                axios.get("/dashboard/jaze_header")
                .then(response => {
                    Nprogress.done()
                    localStorage.removeItem('socEnable')
                    localStorage.removeItem('socAdmin')
                    localStorage.setItem('socAdmin', response.data.message.Auth.User.id);
                    var socEnable = response.data.message.nodejsenabled
                    localStorage.setItem('socEnable', socEnable.toString());
                    if (localStorage.getItem("socketConnected") != "yes") {
                        const event = new Event('build');
                        window.dispatchEvent(event);
                    }
                    this.headerDetails = response.data.message
                    axios.get("/dashboard/get_sub_acc_data")
                    .then(response => {
                      this.checkDbs=response.data.checkDbs;
                      this.subaccDetails=JSON.parse(response.data.message);
                      this.subaccDetailsTree=JSON.parse(response.data.subaccDetailsTree);
                    }).catch(error => {
                        
                    })
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 403) {
                            router.push("/signin");
                        }
                    }
                })
            });
        },
        fetchGenerateSubAccountsTreeVue() {
            return new Promise((resolve) => {
                axios.get("/dashboard/get_subaccounts_tree_vue")
                .then(response => {
                    this.generateSubAccountsTreeVue = response.data;
                }).catch(error => {
                })
            });
        },
        fetchSubAccountMergeExtraArr() {
            return new Promise((resolve) => {
                axios.get("/dashboard/get_sub_account_merge_extra_arr")
                    .then(response => {
                        this.subAccountMergeExtraArr = JSON.parse(response.data.message);
                    }).catch(error => {
                    })
            });
        },
        fetchfranchiseBbpsAccount() {
            return new Promise((resolve) => {
                axios.get("/dashboard/get_franchiseBbpsAccount")
                    .then(response => {
                        this.franchiseBbpsAccount = (response.data.message);
                    }).catch(error => {
                    })
            });
        },
        fetchAdminDetails(postData) {
            var data = {
                type: postData
            }
            Nprogress.start()
            axios.post('/dashboard/get_admin_details_for_vue', qs.stringify(data, {
            parseArrays: false
            }))
            .then(res => {
                Nprogress.done()
                var parseData = (res.data.message);
                if(postData == "allAdmin"){
                    this.adminDetails['allAdmin'] = parseData.allAdmin;
                }
                else if(postData == "subAdmin"){
                    this.adminDetails['subAdmin'] = parseData.subAdmin;
                }
                else if(postData == "superAdmin"){
                    this.adminDetails['superAdmin'] = parseData.superAdmin;
                }
                else if(postData == "superSubAdmin"){
                    this.adminDetails['superSubAdmin'] = parseData.superSubAdmin;
                }
            })
            .catch(error => console.log(error))
        },
        fetchBankCurrencyCountryDetails(postData){
            var data = {
              type: postData,
            }
            Nprogress.start()
            axios.post('/dashboard/get_bank_currency_country_list', qs.stringify(data, {
              parseArrays: false
            }))
              .then(res => {
                Nprogress.done()
                var parseData = (res.data.message);
                if(postData.includes("bankLists")){
                    this.bankCurrencyCountryDetails['bankLists'] = parseData.bankLists;
                }
                if(postData.includes("currencyLists")){
                    this.bankCurrencyCountryDetails['currencyLists'] = parseData.currencyLists;
                }
                if(postData.includes("countriesLists")){
                    this.bankCurrencyCountryDetails['countriesLists'] = parseData.countriesLists;
                }
                if(postData.includes("countriesFromTable")){
                    this.bankCurrencyCountryDetails['countriesFromTable'] = parseData.countriesFromTable;
                }
                if(postData.includes("timeZoneList")){
                    this.bankCurrencyCountryDetails['timeZoneList'] = parseData.timeZoneList;
                }
                if(postData.includes("isdCodes")){
                    this.bankCurrencyCountryDetails['isdCodes'] = parseData.isdCodes;
                }
              })
              .catch(error => console.log(error))
          },
        clearLayoutState() {
            this.headerDetails = [];
        },
    }
  })